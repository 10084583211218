import "./chatChannelsList.scss";
import "sendbird-uikit/dist/index.css";
import { IonIcon, IonPage } from "@ionic/react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, Stack } from "@mui/material";
import { InputAdornment, TextField } from "@mui/material";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { CustomMessageTypes } from "@src/appV2/Chat/type";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useAppSelector } from "@store/index";
import { documentOutline } from "ionicons/icons";
import { debounce } from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useSendbirdStateContext } from "sendbird-uikit";

import { getChannelShiftTime } from "./channel";
import { fetchChannels } from "./chatProvider";
import { getSendHomeRequestMessage } from "./messages";

const getLastMessageDate = (channel) => {
  const useTimestamp = channel.lastMessage ? channel.lastMessage.createdAt : channel.createdAt;
  const lastMessage = moment(useTimestamp);

  if (moment().add(-1, "day").startOf("day").isAfter(lastMessage)) {
    return lastMessage.format("MM/DD/YYYY");
  }
  if (moment().startOf("day").isAfter(lastMessage)) {
    return "Yesterday";
  }

  return lastMessage.format("hh:mm A");
};

const getLastMessageInfo = ({ lastMessage }, currentUserId) => {
  if (lastMessage) {
    if (lastMessage.customType === CustomMessageTypes.FCM_REQUEST) {
      const data = lastMessage.data ? JSON.parse(lastMessage.data) : {};
      return getSendHomeRequestMessage(data.facilityName);
    }

    const from = lastMessage.sender ? (
      <b>{lastMessage.sender.userId === currentUserId ? "You" : lastMessage.sender.nickname}: </b>
    ) : (
      <></>
    );
    if (lastMessage.messageType === "file") {
      return (
        <>
          <IonIcon icon={documentOutline} /> {from} {lastMessage.name}{" "}
        </>
      );
    }

    return (
      <>
        {from} {lastMessage.message}
      </>
    );
  }

  return "No messages";
};

export function ChatChannels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const { userId } = useDefinedWorker();
  const { facilityId } = useParams<{ facilityId: string }>();
  const channels = useAppSelector((store) => store.chatStore.channels);
  const currentChannelUrl = facilityId + "_" + userId;
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;

  const channelClick = (newChannelUrl) => {
    const [facilityId] = newChannelUrl.split("_");
    history.push(TabRouterPath.CHAT_CHANNELS_LIST + "/" + facilityId);
  };
  const [isLoading, setIsLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchChannels = useCallback(
    debounce(async (searchTerm) => {
      setIsLoading(true);
      await fetchChannels({ searchInput: searchTerm, sdk, dispatch });
      setIsLoading(false);
    }, 200),
    [sdk, dispatch, searchInput]
  );

  useEffect(() => {
    debouncedFetchChannels(searchInput);

    return () => {
      debouncedFetchChannels.cancel();
    };
  }, [searchInput, debouncedFetchChannels]);

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Facility Chats"
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.MY_SHIFTS} />}
          />
        }
        containerVariant="without-margin"
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "#fff",
            m: 2,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search facilities..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {!channels.length && (
              <h3 style={{ textAlign: "center", margin: 20 }}>No chats loaded</h3>
            )}

            {!!channels.length && (
              <div
                className="channels-container"
                style={{
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 120px)",
                }}
              >
                {channels.map((channel, index, array) => {
                  const classes = ["channel"];

                  if (channel.url === currentChannelUrl) {
                    classes.push("selected");
                  }

                  if (index > 0) {
                    classes.push("channel-not-first");
                  }
                  if (index === array.length - 1) {
                    classes.push("channel-last");
                  }

                  const { facilityName } = channel.metadata;

                  return (
                    <div
                      role={"button"}
                      tabIndex={0}
                      key={channel.url}
                      className={classes.join(" ")}
                      onClick={() => channelClick(channel.url)}
                    >
                      <div className="channel-name">
                        <b>{channel.shift?.facility?.name || facilityName || channel.name}</b>
                      </div>
                      <div className="channel-last-message">{getLastMessageDate(channel)}</div>
                      <div className="channel-message">
                        {getLastMessageInfo(channel, sdk.currentUser?.userId)}
                      </div>
                      {channel.unreadMessageCount > 0 ? (
                        <div className="channel-unread">{channel.unreadMessageCount}</div>
                      ) : (
                        <div></div>
                      )}
                      <div className="channel-upcoming-shift">{getChannelShiftTime(channel)}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </PageWithHeader>
    </IonPage>
  );
}
