import { ExclusionForList } from "@src/app/api/exclusion";
import { roundUpHoursToTwoDecimalsUsingCbhRounding } from "@src/appV2/Shifts/utils/timeCalculation";
import { ChatChannel } from "@store/chat/model";
import moment from "moment-timezone";
import "sendbird-uikit/dist/index.css";
import "./style.scss";

export type ShiftStatus =
  | "tomorrow"
  | "ahead"
  | "in progress"
  | "nothing"
  | "past"
  | "loading"
  | "today";

const getStatus = (channel?: ChatChannel, activeChatWindowDays?: number): ShiftStatus => {
  if (!channel) {
    return "loading";
  }
  if (!channel.shift) {
    // if the feature flag is disabled or there's no metadata
    if (!channel.metadata.lastBooked) {
      return "nothing";
    }

    return "past";
  }

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  const tomorrowEndOfDay = moment().add(1, "day").endOf("day");
  const todayEndOfDay = moment().endOf("day");

  if (end.isBefore(moment())) {
    return "past";
  }
  if (start.isAfter(moment())) {
    if (start.isBefore(todayEndOfDay)) {
      return "today";
    }
    if (start.isBefore(tomorrowEndOfDay)) {
      return "tomorrow";
    }
    return "ahead";
  }

  return "in progress";
};

interface GetMessageInputDefinition {
  isLoadingExclusion: boolean;
  sdk?: SendBird.SendBirdInstance;
  actionBy?: ExclusionForList["actionBy"];
}
export function getMessageInputDefinition(definition: GetMessageInputDefinition) {
  const { isLoadingExclusion, sdk, actionBy } = definition;
  const disabledMessage = getChannelDisabledMessage(actionBy);

  // to make it compliant with sendbird's renderMessageInput
  if (isLoadingExclusion || !sdk) {
    return () => null;
  } else if (disabledMessage) {
    // eslint-disable-next-line react/display-name
    return () => <div className="sendbird-disabled-chat">{disabledMessage}</div>;
  } else {
    return undefined;
  }
}

function getChannelDisabledMessage(actionBy?: ExclusionForList["actionBy"]): string | undefined {
  if (actionBy === "AGENT") {
    return "You blocked this workplace. Chat disabled.";
  } else if (actionBy === "FACILITY") {
    return "You are blocked by this workplace. Chat disabled.";
  }
}

export function getChannelShiftTime(channel?: ChatChannel): string {
  if (!channel) {
    return "Loading...";
  }
  if (!channel.shift) {
    return "No upcoming shifts";
  }

  const status = getStatus(channel);
  if (["nothing", "past"].includes(status)) {
    return "No upcoming shifts";
  }

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  const until = `until ${end.format("h:mm A")}, ${roundUpHoursToTwoDecimalsUsingCbhRounding(
    end.diff(start, "hours", true)
  )} hrs`;
  const at = `at ${start.format("hh:mm A")} ${until}`;

  if (status === "in progress") {
    return `Shift in progress ${until}`;
  }

  let date = "";
  if (status === "today") {
    date = `today ${at}`;
  }
  if (status === "tomorrow") {
    date = `tomorrow, ${start.format("MMM DD")} ${at}`;
  }
  if (status === "ahead") {
    date = `${start.format("dddd[,] MMM DD")} ${at}`;
  }

  return "Next shift " + date;
}
